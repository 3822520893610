<template>
  <v-container class="resources mb-12">
    <h2>New Patient Forms</h2>
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-card hover color="rgba(0,55,103,0.2)">
          <v-list dense color="transparent">
             <div class="px-4">
               <h3>Required</h3>
               <p class="subheading font-weight-regular mb-0">If you are a new patient, please print, fill out, and bring the following five forms with you to your first visit.</p>
             </div>
            <v-list-item>
              <a href="assets/docs/BRIEF%20MEDICAL%20HISTORY.doc" download>Brief Medical History</a>
            </v-list-item>
            <v-list-item>
              <a href="assets/docs/Clientinformation 12-14-17.docx" download>Client Information</a>
            </v-list-item>
            <v-list-item>
              <a href="assets/docs/Consent%20for%20Release%20%202023.docx" download>Consent for Release</a>
            </v-list-item>
            <v-list-item>
              <a href="assets/docs/Consent for Treatment 12-14-17.docx" download>Consent for Treatment</a>
            </v-list-item>
            <v-list-item>
              <a href="assets/docs/Financial Obligations 12-14-17.docx" download>Financial Obligations</a>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-6">
        <v-card hover color="rgba(0,55,103,0.2)">
          <v-list dense color="transparent">
             <div class="px-4">
               <h3>Optional</h3>
               <p class="subheading font-weight-regular mb-0">Please print and fill out this form if you require us to access confidential records, or speak with another person regarding your medical information.</p>
             </div>
            <v-list-item>
              <a href="assets/docs/Consent%20for%20Release%20Confidentialitial%20Form%202023.docx" download>Updated Confidentiality Form</a>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="col-12">
        <v-card hover color="rgba(0,55,103,0.2)" class="pa-3">
          <p class="title text-center mb-1">
            Be sure to bring your current insurance card, referral on file, if required by insurance, and copayment as required by insurance.
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    crisisLine: [
      {
        text: "If you are in a crisis or emotional distress, please call:"
      },
      {
        text: "Com-Care Crisis: 316 660 7500",
      },
      {
        text: "National Suicide Hotline: 800 273 8255"
      },
      {
        text: "National Suicide Text: 741741"
      }
    ]
  })
};
</script>
